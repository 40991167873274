import React from 'react'
import { EnglishApp } from "./english/components/App";
import { NepaliApp } from "./nepali/components/App";
// import Preloader from './preloader/preloader'
import Home from "./home/home";
import Header from "./home/header";
import Footer from "./home/footer";
import About from './aboutus/about';
import Contact from './contactus/contact';
import Privacypolicy from './privacypolicy/privacypolicy';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
function App() {
  return (
    <>
      <Router>
        <Header/>
       {/* <div id='preloader-all'><Preloader/></div>  */}
        <Switch>
          <Route exact path="/" render={() => {
            document.title = "Typeshala Online: Elevate Your Nepali Typing Proficiency!"
            return (
              <>
                <Home />   
              </>
            )                 
            }
          }
          >
          </Route>
          <Route exact path="/typeshala-english" render={() => {
            document.title = "Typeshala online -English"
            return (
              <>
                <EnglishApp />
              </>
            )
          }
          }
          >
          </Route>
          <Route exact path="/typeshala-nepali" render={() => {
            document.title = "Typeshala online -Nepali"
            return (
              <>
                <NepaliApp />
              </>
            )
          }
          }>

          </Route>
          <Route exact path="/about" render={() => {
            document.title = "Typeshala online -About"
            return (
              <>
                <About />
              </>
            )
          }
          }>
          </Route>
          <Route exact path="/contact" render={() => {
            document.title = "Typeshala online -Contact"
            return (
              <>
                <Contact />
              </>
            )
          }
          }>
          </Route>
          <Route exact path="/privacypolicy" render={() => {
            document.title = "Typeshala online -Privacy Policy"
            return (
              <>
                <Privacypolicy />
              </>
            )
          }
          }>
          </Route>
          {/* <Route path="/">
            <Home />
          </Route> */}
        </Switch>
        <Footer/>
      </Router>
    </>
    
  );
}

export default App;
