import React from 'react'
import './about.css'

export default function About() {
    
    return (
        <div>
            <div class="about-section">
                <div class="inner-container">
                    <h1>About Us</h1>

                    <p class="text">I am a student from Nepal who have interest in programming, coding, designing, and many more like that. Sometimes I create some videos in YouTube when I am free from study and try to Write content for website sometimes. <br/>
                                And this is the online version of typeshala.<br/>
                        Main content category in website:<br/>
                        ● Online Typeshala <br/>
                        ● Tutorial Related to Typeshala <br/>
                        <br/>Email: contact@smilepant.com</p>
                </div>
          </div>
        </div> 
     )


}