import React, { Component } from 'react';
import '../stylesheets/keyboard.css'

export class Keyboard extends Component {

  handleKeyClass(inputKeycode, keySymbol, width = '', multi = false) {
    let str = 'keyboard__key ' + width + ' '
    const { keyCode, showStats, incorrect, correctLetter, remainingText, inputSelected, correctLetterCase, caps, incorrectArr } = this.props
    if (showStats !== true) {
      if (keyCode === inputKeycode && incorrect === true) {
        str += 'keyboard__highlightedKey '
      }
      if (correctLetter === keySymbol && multi === false && remainingText !== '') {
        str += 'keyboard__correctKey'
      } else if (keySymbol.includes(correctLetter) && multi === true && remainingText !== '') {
        str += 'keyboard__correctKey'
      }
      if (inputSelected === 'num' && correctLetter === ' ' && keySymbol === 'Enter') {
        str += 'keyboard__correctKey'
      }
      if (correctLetterCase === 'uppercase' && (inputKeycode === 'ShiftLeft' || inputKeycode === 'ShiftRight') && caps === 'keyboard__key keyboard__width-threeQuarts') {
        str += 'keyboard__correctKey'
      }
      if (correctLetterCase === 'lowercase' && (inputKeycode === 'ShiftLeft' || inputKeycode === 'ShiftRight') && caps === 'keyboard__key keyboard__width-threeQuarts keyboard__capsOn') {
        str += 'keyboard__correctKey'
      }
    }

    if (keySymbol !== 'Enter' && keySymbol !== 'ShiftLeft' && keySymbol !== 'ShiftRight' && keySymbol !== 'Caps' && keySymbol !== 'Tab' && keySymbol !== 'Backspace')
      if ((incorrectArr.includes(keySymbol.toLowerCase()[0])
        || incorrectArr.includes(keySymbol.toLowerCase()[1]))
        && showStats === true) {
        str += 'keyboard__capsOn'
      }

    return str;
  }
  render() {
    const { keyboardScaler, caps } = this.props
    return (
      <div className="keyboard_nepali" style={{ zoom: keyboardScaler }}>
        <div className={this.handleKeyClass(192, '`~', '', true)}><div className="keyboard_key_main">&#96;</div><div className="keyboard_key_nepali_ii">&#96;<br />~</div></div>
        <div className={this.handleKeyClass(49, '1!', '', true)}><div className="keyboard_key_main">1</div><div className="keyboard_key_nepali_ii">1<br />!</div></div>
        <div className={this.handleKeyClass(50, '2@', '', true)}><div className="keyboard_key_main">2</div><div className="keyboard_key_nepali_ii">2<br />@</div></div>
        <div className={this.handleKeyClass(51, '3#', '', true)}><div className="keyboard_key_main">3</div><div className="keyboard_key_nepali_ii">3<br />#</div></div>
        <div className={this.handleKeyClass(52, '4$', '', true)}><div className="keyboard_key_main">4</div><div className="keyboard_key_nepali_ii">4<br />$</div></div>
        <div className={this.handleKeyClass(53, '5%', '', true)}><div className="keyboard_key_main">5</div><div className="keyboard_key_nepali_ii">5<br />%</div></div>
        <div className={this.handleKeyClass(54, '6^', '', true)}><div className="keyboard_key_main">6</div><div className="keyboard_key_nepali_ii">6<br />^</div></div>
        <div className={this.handleKeyClass(55, '7&', '', true)}><div className="keyboard_key_main">7</div><div className="keyboard_key_nepali_ii">7<br />&</div></div>
        <div className={this.handleKeyClass(56, '8*', '', true)}><div className="keyboard_key_main">8</div><div className="keyboard_key_nepali_ii">8<br />*</div></div>
        <div className={this.handleKeyClass(57, '9(', '', true)}><div className="keyboard_key_main">9</div><div className="keyboard_key_nepali_ii">9<br />(</div></div>
        <div className={this.handleKeyClass(48, '0)', '', true)}><div className="keyboard_key_main">0</div><div className="keyboard_key_nepali_ii">0<br />)</div></div>
        <div className={this.handleKeyClass(189, '-_', '', true)}><div className="keyboard_key_main">-</div><div className="keyboard_key_nepali_ii">-<br />_</div></div>
        <div className={this.handleKeyClass(187, '=+', '', true)}><div className="keyboard_key_main">=</div><div className="keyboard_key_nepali_ii">=<br />+</div></div>
        <div id="normal_key" className={this.handleKeyClass(8, 'Backspace', 'keyboard__width-plusHalf', false)}>&larr;<br /><div className="keyboard_key_main">&nbsp;</div><div>&nbsp;</div></div>

        <div id="normal_key" className={this.handleKeyClass(9, 'Tab', 'keyboard__width-plusHalf', false)}><div className="keyboard_key_main">&nbsp;</div>TAB<br />&nbsp;</div>
        <div className={this.handleKeyClass(81, 'q')}><div className="keyboard_key_main">Q</div><div className="keyboard_key_nepali_ii">q<br />Q</div></div>
        <div className={this.handleKeyClass(87, 'w')}><div className="keyboard_key_main">W</div><div className="keyboard_key_nepali_ii">w<br />W</div></div>
        <div className={this.handleKeyClass(69, 'e')}><div className="keyboard_key_main">E</div><div className="keyboard_key_nepali_ii">e<br />E</div></div>
        <div className={this.handleKeyClass(82, 'r')}><div className="keyboard_key_main">R</div><div className="keyboard_key_nepali_ii">r<br />R</div></div>
        <div className={this.handleKeyClass(84, 't')}><div className="keyboard_key_main">T</div><div className="keyboard_key_nepali_ii">t<br />T</div></div>
        <div className={this.handleKeyClass(89, 'y')}><div className="keyboard_key_main">Y</div><div className="keyboard_key_nepali_ii">y<br />Y</div></div>
        <div className={this.handleKeyClass(85, 'u')}><div className="keyboard_key_main">U</div><div className="keyboard_key_nepali_ii">u<br />U</div></div>
        <div className={this.handleKeyClass(73, 'i')}><div className="keyboard_key_main">I</div><div className="keyboard_key_nepali_ii">i<br />I</div></div>
        <div className={this.handleKeyClass(79, 'o')}><div className="keyboard_key_main">O</div><div className="keyboard_key_nepali_ii">o<br />O</div></div>
        <div className={this.handleKeyClass(80, 'p')}><div className="keyboard_key_main">P</div><div className="keyboard_key_nepali_ii">p<br />P</div></div>
          <div className={this.handleKeyClass(219, '[{', '', true)}><div className="keyboard_key_main">[</div><div className="keyboard_key_nepali_ii">[<br />&#123;</div></div>
          <div className={this.handleKeyClass(221, ']}', '', true)}><div className="keyboard_key_main">]</div><div className="keyboard_key_nepali_ii">]<br />&#125;</div></div>
          <div className={this.handleKeyClass(220, '\\|', '', true)}><div className="keyboard_key_main">\</div><div className="keyboard_key_nepali_ii">\<br />|</div></div>

          <div id="normal_key" className={caps}><div className="keyboard_key_main">&nbsp;</div>CAPS<br />&nbsp;</div>
          <div className={this.handleKeyClass(65, 'a')}><div className="keyboard_key_main">A</div><div className="keyboard_key_nepali_ii">a<br />A</div></div>
          <div className={this.handleKeyClass(83, 's')}><div className="keyboard_key_main">S</div><div className="keyboard_key_nepali_ii">s<br />S</div></div>
          <div className={this.handleKeyClass(68, 'd')}><div className="keyboard_key_main">D</div><div className="keyboard_key_nepali_ii">d<br />D</div></div>
          <div className={this.handleKeyClass(70, 'f')}><div className="keyboard_key_main">F</div><div className="keyboard_key_nepali_ii">f<br />F</div></div>
          <div className={this.handleKeyClass(71, 'g')}><div className="keyboard_key_main">G</div><div className="keyboard_key_nepali_ii">g<br />G</div></div>
          <div className={this.handleKeyClass(72, 'h')}><div className="keyboard_key_main">H</div><div className="keyboard_key_nepali_ii">h<br />H</div></div>
          <div className={this.handleKeyClass(74, 'j')}><div className="keyboard_key_main">J</div><div className="keyboard_key_nepali_ii">j<br />J</div></div>
          <div className={this.handleKeyClass(75, 'k')}><div className="keyboard_key_main">K</div><div className="keyboard_key_nepali_ii">k<br />K</div></div>
          <div className={this.handleKeyClass(76, 'l')}><div className="keyboard_key_main">L</div><div className="keyboard_key_nepali_ii">l<br />L</div></div>
          <div className={this.handleKeyClass(186, ';:', '', true)}><div className="keyboard_key_main">;</div><div className="keyboard_key_nepali_ii">;<br />:</div></div>
          <div className={this.handleKeyClass(222, '\'"', '', true)}><div className="keyboard_key_main">'</div><div className="keyboard_key_nepali_ii">'<br />"</div></div>

          <div id="normal_key" className={this.handleKeyClass(13, 'Enter', 'keyboard__width-threeQuarts')}><div className="keyboard_key_main">&nbsp;</div>ENTER<br />&nbsp;</div>

          <div id="normal_key" className={this.handleKeyClass('ShiftLeft', '~!@#$%^&*()_+{}|:"<>?', 'keyboard__width-plusOne', true)}><div className="keyboard_key_main">&nbsp;</div><div>SHIFT<br/>&nbsp;</div></div>
          <div className={this.handleKeyClass(90, 'z')}><div className="keyboard_key_main">Z</div><div className="keyboard_key_nepali_ii">z<br />Z</div></div>
          <div className={this.handleKeyClass(88, 'x')}><div className="keyboard_key_main">X</div><div className="keyboard_key_nepali_ii">x<br />X</div></div>
          <div className={this.handleKeyClass(67, 'c')}><div className="keyboard_key_main">C</div><div className="keyboard_key_nepali_ii">c<br />C</div></div>
          <div className={this.handleKeyClass(86, 'v')}><div className="keyboard_key_main">V</div><div className="keyboard_key_nepali_ii">v<br />V</div></div>
          <div className={this.handleKeyClass(66, 'b')}><div className="keyboard_key_main">B</div><div className="keyboard_key_nepali_ii">b<br />B</div></div>
          <div className={this.handleKeyClass(78, 'n')}><div className="keyboard_key_main">N</div><div className="keyboard_key_nepali_ii">n<br />N</div></div>
          <div className={this.handleKeyClass(77, 'm')}><div className="keyboard_key_main">M</div><div className="keyboard_key_nepali_ii">m<br />M</div></div>
          <div className={this.handleKeyClass(188, ',<', '', true)}><div className="keyboard_key_main">,</div><div className="keyboard_key_nepali_ii">,<br />&#60;</div></div>
          <div className={this.handleKeyClass(190, '.>', '', true)}><div className="keyboard_key_main">.</div><div className="keyboard_key_nepali_ii">.<br />&#62;</div></div>
          <div className={this.handleKeyClass(191, '/?', '', true)}><div className="keyboard_key_main">/</div><div className="keyboard_key_nepali_ii">/<br />?</div></div>
          <div id="normal_key" className={this.handleKeyClass('ShiftRight', '~!@#$%^&*()_+{}|:"<>?', 'keyboard__width-plusOne', true)}><div className="keyboard_key_main">&nbsp;</div><div>SHIFT<br/>&nbsp;</div></div>
          <div className={this.handleKeyClass(32, ' ', 'keyboard__spacebar')}></div>
        </div>
    )
  }
}
