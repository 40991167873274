import React from "react";
import "./header.css";
import {  Link } from "react-router-dom";
export default function Header() {
  return (
    <div>
      <div className="main-header">
        <h2 className="header-text">Typeshala</h2>
        <ul className="header">
          <Link to="#">
            <li>Nepali (Advance (soon))</li>
          </Link>
          <Link to="/typeshala-nepali">
            <li>Nepali</li>
          </Link>
          <Link to="/typeshala-english">
            <li>English</li>
          </Link>
          <a href="https://smilepant.com/contact" target="_blank">
            <li>Contact Us</li>
          </a>
          <a href="https://smilepant.com/aboutme" target="_blank">
            <li>About Us</li>
          </a>

          <Link to="/">
            <li>Home</li>
          </Link>
        </ul>
      </div>
    </div>
  );
}
