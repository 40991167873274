import React from "react";
import "./footer.css";

export default function Footer() {
  return (
    <div class="footer">
      <p>
        Copyright &copy; Typeahala Online, All right reserved{" "}
        <a href="https://smilepant.com/privacy-policy"> Privacy Policy</a>
      </p>
    </div>
  );
}
