import React from "react";
import "./home.css";
import {Link } from "react-router-dom";
function Home() {
  return (
    <div className="home_main">
      <div class="overlay">
        <div className="heading">
          <h1>Typeshala</h1>
          <h2>Online</h2>
        </div>
        <h3>Finest platforms for honing your Nepali and English typing skills.</h3>
        <p>
          <strong>Typeshala</strong> is the best nepali typing tutor ever. This
          is the online version of Typeshala Typeshala Online
        </p>
        <Link to="/typeshala-english">
          <button>Typeshala -English</button>
        </Link>
        <Link to="/typeshala-nepali">
          <button>Typeshala -Nepali</button>
        </Link>
        <div
          className="content-box"
          style={{ textAlign: "left", padding: "25px 10%" }}
        >
          <p>
            <strong>Typeshala</strong> is a typing tutor that is available only
            for 16 bit and 32 bit pc users. Nowadays people are shifting to
            windows 10/11 where
            <strong> typeshala</strong> is not available
            <strong> for windows 10/11</strong>. So, to solve that you have to
            use a virtual machine or emulator. which is don't provide a good
            experience to the users. It is a well-known Nepali typing tutor
            software that aims to help users improve their typing speed and
            accuracy in Nepali. It is widely used in Nepal to teach people of
            all ages and ability levels how to type properly in Nepali, the
            country's official language.
          </p>
          <p>
            The term "Typeshala" is a mix of two Nepali words: "Type," which
            alludes to typing, and "Shala," which means school. This implies
            that Typeshala functions as a virtual school or a platform for
            learning and improving one's typing skills. Typeshala Online key features
            include:
          </p>
          <h2> 1. Nepali Typing Practice</h2>
          <p>
            Typeshala Online provides a variety of activities and tutorials for
            practicing Nepali typing. It gives users paragraphs, articles, and
            sentences to type in Nepali, allowing them to become more familiar
            with the characters and increase their typing speed.
          </p>
          <h2>2. Typing Games</h2>
          <p>
            Typeshala Online features typing games that encourage users to type
            swiftly and accurately to make the learning experience more engaging
            and fun. These games give a sense of enjoyment and competition,
            encouraging players to keep practicing.
          </p>
          <h2>3. Typing Speed and Accuracy exams</h2>
          <p>
            Typeshala Online offers periodic typing speed and accuracy exams to
            help users track their development. These tests assist users in
            identifying areas for improvement and tracking the evolution of
            their typing skills over time
          </p>
          <h2> 4. Virtual Keyboard</h2>
          <p>
            The app includes an on-screen virtual keyboard with Nepali
            characters. This visual assistance assists novices in learning the
            layout of the Nepali keyboard and practicing without the need for
            physical Nepali key labels.{" "}
          </p>
          <h2>5. User-Friendly Interface</h2>
          <p>
            Typeshala Online has a basic and user-friendly interface that makes
            it accessible to people of all ages and levels of computer literacy.
            The simple layout allows users to effortlessly move through the
            classes and tasks.
          </p>
          <h2> 6. Customization Options</h2>
          <p>
            Users can adjust the level of difficulty of the typing classes and
            select their own practice texts. This adaptability means that
            Typeshala Online can meet the demands of individual students.
          </p>

          <h2>Conclusion</h2>
          <p>
            To summarize, Typeshala Online is an excellent resource for anyone
            looking to enhance their typing skills in Nepali. Typeshala Online
            provides an effective and entertaining platform for improving Nepali
            typing proficiency, whether for educational objectives, professional
            advancement, or personal pleasure. By using Typeshala Online on a
            regular basis, users can improve their typing efficiency and, as a
            result, increase their productivity in all parts of their everyday
            lives.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Home;
