import React from 'react'
import './contact.css'
export default function Contact() {

    return (
    <>
    <form action='/' class='decor'>
                <div class='form-inner'>
                    <h1>Contact us</h1><br/>
                    <input type='text' placeholder='Username'/><br/>
                    <input type='email' placeholder='Email'/><br/>
                    <textarea placeholder='Message...' rows='5'></textarea><br/>
                    <button type='submit' href='/'>Submit</button>
                    <p>Note: It is better to send mail to me rather then using this form for fast response <br/>
                    Email ID: contact@smailepant.com
                    </p>
                
         </div>
    </form>
   </>
                )
}