import React, { Component } from 'react';
import '../stylesheets/sidebar.css'


export class Sidebar extends Component {


  render() {
    const {showMenu, displayText} = this.props
    return (
      <div className={showMenu ? "rightsideShow" : 'rightside'}>
        <div className="sidebar">
          <div className="sidebar__box">
            <div className="sidebar__title">
              <h1 className="sidebar__tile__h1">Lessons</h1>
            </div>
            <div className="sidebar__item" onClick={() => displayText('text')}>
              <p className="sidebar__item__p">Simple Typing Practice<br/>(change above text)</p>
            </div>
            <div className="sidebar__item" onClick={() => displayText('num')}>
              <p className="sidebar__item__p">Numbers</p>
            </div>
            <div className="sidebar__item" onClick={() => displayText('sym')}>
              <p className="sidebar__item__p">Symbols</p>
            </div>
          </div>
          <div className="sidebar__box">
            <div className="sidebar__title">
              <h1 className="sidebar__tile__h1">Typing Challenges</h1>
            </div>
            <div className="sidebar__switch">
              <div className="sidebar__switch__option" onClick={() => displayText('test1')}>
                <p>Level 1 <br/>2 Minutes</p>
              </div>
              <div className="sidebar__switch__option" onClick={() => displayText('test3')}>
                <p>Level 2 <br/>4 Minutes</p>
              </div>
              <div className="sidebar__switch__option" onClick={() => displayText('test5')}>
                <p>Level 3 <br/>8 Minutes</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
